<template>
  <div class="recommend-card">
    <div class="flex flex-center flex-between">
      <div class="label" v-if="label">
        <p class="label-text">{{ label }}</p>
      </div>
      <slot name="head"></slot>
    </div>
    <slot></slot>
    <template v-if="!nomore">
      <div class="line"></div>
      <div class="more flex flex-center" @click="$emit('more')">
        <p class="more-text">查看更多</p>
        <img class="arrow-right" src="@/assets/list/arrow-right.png" />
      </div>
    </template>
  </div>
</template>

<script>
import { px2rem } from "../mixins";

export default {
  mixins: [px2rem],
  props: {
    label: { type: String, default: "" },
    nomore: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";
@import "../common.scss";

.recommend-card {
  width: 363 * $px;
  box-sizing: border-box;
  margin: auto;
  background: #fff;
  border-radius: 12 * $px;
  // padding: 0 10 * $px 12 * $px;
}

.label {
  padding: 12 * $px 0;
}

.label-text {
  font-size: 17 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #262626;
}

.line {
  width: 343 * $px;
  height: 1 * $px;
  background: rgba(52, 56, 75, 0.1);
  margin-top: 25 * $px;
}

.more {
  width: 100%;
  padding-top: 13 * $px;
  .more-text {
    font-size: 12 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: rgba(52, 56, 75, 0.5);
  }
}

.arrow-right {
  width: 4 * $px;
  height: 7 * $px;
  margin-left: 6 * $px;
}
</style>
