<template>
  <div class="kind-bar fx-row fx-nowrap">
    <div class="item" v-for="(item, index) in dataList" :key="index" @click="selectClickIndex(index)">
      <div class="itemTxt" :style="selectStyle(index)">{{ item.name }}</div>
      <div class="itemWrap" v-if="selectIndex == index"></div>
    </div>
  </div>
</template>

<script>
import { px2rem } from "../mixins";

export default {
  mixins: [px2rem],
  name: "kind-bar",
  props: {
    dataList: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectIndex: 0,
    };
  },
  methods: {
    selectStyle(index) {
      return this.selectIndex == index
        ? { fontWeight: 500, fontSize: this.px2rem(16), color: "#262626" }
        : {
            fontWeight: 400,
            fontSize: this.px2rem(13),
            color: "rgba(52,56,75,0.75)",
            paddingTop: this.px2rem(3),
          };
    },
    selectClickIndex(index) {
      if (this.selectIndex === index) {
        return void 0;
      }
      this.selectIndex = index;
      this.$emit("change", this.dataList[index]);
    },
  },
  watch: {
    dataList(val) {
      this.selectIndex = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.kind-bar {
  height: 38 * $px;
  background: linear-gradient(
    180deg,
    rgba(34, 164, 255, 0.15),
    rgba(37, 157, 255, 0) 75%
  );
  padding: 12 * $px 12 * $px 0 12 * $px;
  border-top-left-radius: 12 * $px;
  border-top-right-radius: 12 * $px;
  .item {
    margin-right: 16 * $px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .itemTxt {
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: rgba(52, 56, 75, 0.75);
      margin-bottom: 2 * $px;
    }
    .itemWrap {
      width: 20px;
      height: 4px;
      opacity: 1;
      background: linear-gradient(270deg, rgba(135, 207, 255, 0), #4fa1ff);
      border-radius: 4px;
    }
  }
}
</style>
