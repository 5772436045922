<template>
  <div class="block-item" v-on="$listeners" @click="jumptoDetail(data)">
    <div class="img" :style="{ backgroundImage: `url(${data.img})` }">
      <!-- <div class="mark flex flex-column flex-center" v-if="!noindex && index">
        <p class="mark-top">TOP</p>
        <p class="mark-rank">{{ index }}</p>
      </div> -->
      <template v-if="data && data.topTag">
        <div class="mark flex flex-column flex-center" v-if="!noindex && index">
          <img :src="data.topTag" alt="" />
        </div>
      </template>
      <div class="discount" v-if="data.discount">{{data.discount}}折商旅特惠</div>
    </div>
    <p class="name overflow">{{ data.name }}</p>
    <p class="desc overflow" v-if="data.desc !== void 0">{{ data.desc }}</p>
    <p class="price" v-if="data.price !== void 0">
      <span class="price-fix">{{data.pricePerfixed}}</span>{{data.price}}<span class="price-fix">{{data.priceSuffix}}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "block-item",
  props: {
    data: { type: Object, default: () => ({}) },
    noindex: { type: Boolean, default: false },
  },
  computed: {
    index() {
      return this.data.index < 3 ? this.data.index + 1 : false;
    },
  },
  methods: {
    jumptoDetail(item) {
      this.$emit("jumptoDetail", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";
@import "../common.scss";

.img {
  width: 109 * $px;
  height: 90 * $px;
  border-radius: 10 * $px;
  position: relative;
  overflow: hidden;
  // object-fit: cover;
  background-size: cover;
  background-position: center;
}

.name {
  width: 109 * $px;
  font-size: 14 * $px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #262626;
  margin-top: 8 * $px;
}

.desc {
  width: 109 * $px;
  font-size: 12 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: rgba(52, 56, 75, 0.75);
  margin-top: 2 * $px;
}

.mark {
  width: 22 * $px;
  height: 32 * $px;
  // background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
  border-radius: 3 * $px 3 * $px 2 * $px 4 * $px 2 * $px;
  position: absolute;
  top: 0;
  left: 4 * $px;
  img {
    width: 22 * $px;
    height: 32 * $px;
    object-fit: cover;
  }
}

.mark-top {
  font-size: 9 * $px;
  font-family: DINAlternate, DINAlternate-Bold;
  font-weight: 700;
  color: #ffffff;
  transform-origin: center center;
  transform: scale((9/11));
}
.discount {
  width: 84 * $px;
  height: 20 * $px;
  background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
  border-radius: 0px 8 * $px 0px 8 * $px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  font-size: 11 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  color: #ffffff;
}
.mark-rank {
  font-size: 12 * $px;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  color: #ffffff;
}

.price {
  font-size: 16 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ff6814;
  .price-fix {
    font-size: 11 * $px;
    display: inline-block;
    transform-origin: center center;
    transform: scale(0.9);
  }
}
</style>
