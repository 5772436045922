<template>
  <div :class="isIos?'kind-popup':'kind-popupAndriod'">
    <div class="showList">
      <div class="itemContain">
        <div :class="slecetIndexList.includes(0) ? 'item selectItem' : 'item'" @click="selctItem(0)">
          不限
        </div>
        <div :class="
            slecetIndexList.includes(index + 1) ? 'item selectItem' : 'item'
          " v-for="(item, index) in showList" @click="selctItem(index + 1)" :key="index">
          {{ item.name }}
        </div>
      </div>
      <div class="item-select" @click="showHideList" :style="show?{'color':'#0078ff'}:{'color':'rgba(52,56,75,0.75)'}">
        筛选<img src="@/assets/list/select.png" class="selectImg" v-if="show" /><img src="@/assets/list/unSelect.png" class="selectImg" v-if="!show" />
        <div class="selectNumber" v-if="slecetHideIndexList.length!=0">{{slecetHideIndexList.length}}</div>
      </div>
    </div>
    <hl-popup v-model="show" position="top" round :style="{
        height: '425px',
        top: '89px',
      }" @close='popupCancel'>
      <div class="popup-contain">
        <div class="contain">
          <div :class="
              slecetHideIndexList.includes(index)
                ? 'hideItem selectHideItem'
                : 'hideItem'
            " v-for="(item, index) in hideList" @click="selctHideItem(index)" :key="index">
            {{ item.name }}
          </div>
        </div>
        <div class="foot">
          <div class="cancel" @click="popupCancel">取消</div>
          <div class="empty" @click="popupEmpty">清空筛选</div>
          <div class="confirm" @click="popupConfirm">确认</div>
        </div>
      </div>
    </hl-popup>
  </div>
</template>
<script>
import env from "@/utils/env";

export default {
  name: "kind-popup",
  props: {
    showList: {
      type: Array,
      default: () => [],
    },
    hideList: {
      type: Array,
      default: () => [],
    },
    slecetIndexListProp: {
      type: Array,
      default: () => [0],
    },
    slecetHideIndexListProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      slecetIndexList: [],
      slecetHideIndexList: [],
      lastSlecetHideIndexList: [],
    };
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
  },
  methods: {
    popupCancel() {
      console.log("popupCancel");
      this.slecetHideIndexList = this.lastSlecetHideIndexList.slice(0);
      this.show = false;
    },
    popupEmpty() {
      this.slecetHideIndexList = [];
    },
    popupConfirm() {
      this.$emit("hideListAxios", this.slecetHideIndexList);
      this.lastSlecetHideIndexList = this.slecetHideIndexList.slice(0);
      this.show = false;
    },
    showHideList() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    selctItem(i) {
      if (i != 0) {
        if (this.slecetIndexList.includes(i)) {
          this.slecetIndexList = this.slecetIndexList.filter(
            (item) => item != i
          );
          this.slecetIndexList = this.slecetIndexList.filter(
            (item) => item != 0
          );
          this.$emit("showListAxios", this.slecetIndexList);
        } else {
          this.slecetIndexList.push(i);
          this.slecetIndexList = this.slecetIndexList.filter(
            (item) => item != 0
          );
          this.$emit("showListAxios", this.slecetIndexList);
        }
      } else {
        this.slecetIndexList = [0];
        this.$emit("showListAxios", this.slecetIndexList);
      }
    },
    selctHideItem(i) {
      if (this.slecetHideIndexList.includes(i)) {
        this.slecetHideIndexList = this.slecetHideIndexList.filter(
          (item) => item != i
        );
      } else {
        this.slecetHideIndexList.push(i);
      }
    },
  },
  watch: {
    slecetIndexListProp(val) {
      this.slecetIndexList = val.slice(0);
    },
    slecetHideIndexListProp(val) {
      this.slecetHideIndexList = val.slice(0);
      // console.log("slecetHideIndexListProp", val);
    },
  },
  created() {
    this.slecetIndexList = this.slecetIndexListProp.slice(0);
    this.slecetHideIndexList = this.slecetHideIndexListProp.slice(0);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.kind-popup {
  background: #ffffff;
  position: fixed;
  z-index: 256;
  left: 0;
  top: calc(44px + constant(safe-area-inset-top));
  top: calc(44px + env(safe-area-inset-top));
}
.showList {
  display: flex;
  position: relative;
  border-bottom: 2 * $px solid #f2f4f7;
  z-index: 200;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding-left: 16 * $px;
  padding-right: 6 * $px;
  width: 353 * $px;
  height: 46 * $px;
  // overflow: hidden;
  .itemContain {
    height: 46 * $px;
    // width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    // overflow-y: hidden;
    // padding-bottom: 25px;
    // flex-wrap: nowrap;
    // padding: 8 * $px 0;
    // padding-top: 12*$px;
    .item {
      display: flex;
      align-items: center;
      margin-right: 12 * $px;
      padding: 0 12 * $px;
      height: 30 * $px;
      background: #f6f6f6;
      border-radius: 4 * $px;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #262626;
      flex-shrink: 0;
      // margin: 12 * $px 0;
      // margin-bottom: 8 * $px;
    }
    .selectItem {
      // flex-shrink: 0;
      color: #1985ff;
      background: rgba(25, 133, 255, 0.1);
    }
  }
  .item-select {
    height: 46 * $px;
    width: 48 * $px;
    margin-left: 4 * $px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    position: relative;
    font-weight: 400;
    color: #0078ff;
    .selectImg {
      width: 14 * $px;
      height: 14 * $px;
    }
    .selectNumber {
      width: 14 * $px;
      height: 14 * $px;
      opacity: 1;
      background: #0078ff;
      border-radius: 50%;
      color: #ffffff;
      text-align: center;
      line-height: 14 * $px;
      position: absolute;
      left: 32 * $px;
      top: 9 * $px;
    }
  }
}
.popup-contain {
  // position: relative;
  .contain {
    padding: 16 * $px;
    width: 375 * $px;
    display: flex;
    justify-content: space-between;
    align-items: normal;
    flex-wrap: wrap;
    height: 360px;
    box-sizing: border-box;
    overflow-y: auto;
    padding-bottom: 48px;
    .hideItem {
      width: 80 * $px;
      height: 36px;
      margin-bottom: 8 * $px;
      background: #f7f9fc;
      border-radius: 4 * $px;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #262626;
      line-height: 36 * $px;
    }
    .selectHideItem {
      background: #0078ff;
      color: #ffffff;
    }
  }
  .foot {
    width: 375 * $px;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    background-color: #ffffff;
    justify-content: space-between;
    padding: 14 * $px 20 * $px 15 * $px 20 * $px;
    border-radius: 0 0 0.6rem 0.6rem;
    .cancel {
      font-size: 15 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(51, 56, 76, 0.5);
    }
    .empty {
      width: 72 * $px;
      height: 26 * $px;
      border: 1px solid #0078ff;
      border-radius: 5px;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      text-align: center;
      color: #0078ff;
      line-height: 26 * $px;
    }
    .confirm {
      font-size: 15 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #0078ff;
    }
  }
}
.kind-popupAndriod {
  background: #ffffff;
  position: fixed;
  z-index: 256;
  left: 0;
  top: 88px;
}
</style>
