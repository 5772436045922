<template>
  <c-loading :loading="isloading">
    <div class="list">
      <navigation-bar :type="2" arrCityName="" @changeSearch="changeSearch" :originCName="pageCity.cityName" @search="inputSearch"></navigation-bar>
      <kind-popup @showListAxios="showListAxios" @hideListAxios="hideListAxios" :slecetHideIndexListProp="slecetHideIndexList"
        :slecetIndexListProp="slecetIndexList" :showList="showList" :hideList="hideList" v-show="recommendShowComputed"></kind-popup>
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" :class="isIos?'mescroll':'mescrollAndriod'">
        <div class="contain" :style="showList.length==0?'paddingTop:52px':'paddingTop:0px'">
          <recommend-card @more="recommendShowMore" class="recommend-card" v-if="showList.length!=0">
            <kind-bar :dataList="showList" @change="handleChangeKind" ref="kindBar"></kind-bar>
            <div :style="{ height: px2rem(12) }"></div>
            <div class="flex flex-wrap flex-start recommend-list">
              <template v-for="(item, index) in hotTicketList">
                <block-item :key="'block-item' + index" :data="item" :style="{ marginTop: index > 2 ? px2rem(20) : '' }" @jumptoDetail="jumptoDetail">
                </block-item>
                <div v-if="(index + 1) % 3 !== 0" :key="'block-item-div' + index" :style="{ width: px2rem(8) }"></div>
              </template>
              <placeholder v-if="hotTicketList.length === 0" style="width:100%"></placeholder>
            </div>
          </recommend-card>
          <div class="entity-item" v-for="(item, index) in dataList" :key="index">
            <list-item :data="item" @jumptoDetail="jumptoDetail"></list-item>
          </div>
          <placeholder v-if="dataList.length === 0"></placeholder>
        </div>
      </mescroll-vue>
    </div>
  </c-loading>
</template>

<script>
import NavigationBar from "./components/navigation-bar";
import * as LocalData from "@/service/localData";
// import localStorage from "@/utils/localStorage";
import RecommendCard from "./components/recommendList-card";
import listItem from "./components/list-item";
import blockItem from "./components/block-item";
import kindPopup from "./components/kind-popup";
import { px2rem } from "./mixins";
import kindBar from "./components/kind-bar";
import MescrollVue from "mescroll.js/mescroll.vue";
import service from "@/service";
import get from "@/utils/get";
import Placeholder from "@/components/placeholder";
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";
// import localize from "../../utils/localize";

// const { getLocation } = localize;

export default {
  mixins: [px2rem],
  name: "list",
  components: {
    NavigationBar,
    kindBar,
    listItem,
    MescrollVue,
    kindPopup,
    RecommendCard,
    blockItem,
    Placeholder,
  },
  data() {
    return {
      observer: null,
      slecetIndexList: [0],
      slecetHideIndexList: [],
      pageCity:
        LocalData.getCity().default == true
          ? LocalData.getOriginCity()
          : LocalData.getCity(),
      //获取出发地
      isloading: false,
      themeList: [
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 1,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 1,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 1,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
        // {
        //   name: "暖春打卡",
        //   icon: "",
        //   code: "",
        //   url: "",
        //   recommendFlag: 0,
        // },
      ],
      hotTicketList: [],
      //mock数据
      recommendData: [
        {
          productName: "上海景点",
          img: "",
          topTag: "",
          productTypeTag: "",
          url: "",
          salePrice: "198",
          pricePerfixed: "¥",
          priceSuffix: "起",
          description: "",
          cityFlag: "",
          bookDay: "",
          scenicTag: "",
        },
        {
          productName: "上海景点",
          img: "",
          topTag: {
            top: "",
            topNumber: 1,
            bkcolour: "rgba(1,1,1,1)",
            colour: "rgba(1,1,1,1)",
            bkrcolour: "rgba(1,1,1,1)",
          },
          productTypeTag: "",
          url: "",
          salePrice: "198",
          pricePerfixed: "¥",
          priceSuffix: "起",
          description: "",
          cityFlag: "",
          bookDay: "",
          scenicTag: "",
        },
        {
          productName: "上海景点",
          img: "",
          topTag: {
            top: "",
            topNumber: 1,
            bkcolour: "rgba(1,1,1,1)",
            colour: "rgba(1,1,1,1)",
            bkrcolour: "rgba(1,1,1,1)",
          },
          productTypeTag: "",
          url: "",
          salePrice: "198",
          pricePerfixed: "¥",
          priceSuffix: "起",
          description: "",
          cityFlag: "",
          bookDay: "",
          scenicTag: "",
        },
        {
          productName: "上海景点",
          img: "",
          topTag: {
            top: "",
            topNumber: 1,
            bkcolour: "rgba(1,1,1,1)",
            colour: "rgba(1,1,1,1)",
            bkrcolour: "rgba(1,1,1,1)",
          },
          productTypeTag: "",
          url: "",
          salePrice: "198",
          pricePerfixed: "¥",
          priceSuffix: "起",
          description: "",
          cityFlag: "",
          bookDay: "",
          scenicTag: "",
        },
        {
          productName: "上海景点",
          img: "",
          topTag: {
            top: "",
            topNumber: 1,
            bkcolour: "rgba(1,1,1,1)",
            colour: "rgba(1,1,1,1)",
            bkrcolour: "rgba(1,1,1,1)",
          },
          productTypeTag: "",
          url: "",
          salePrice: "198",
          pricePerfixed: "¥",
          priceSuffix: "起",
          description: "",
          cityFlag: "",
          bookDay: "",
          scenicTag: "",
        },
      ],
      // recommendCardTip: [
      //   { title: "暖春打卡" },
      //   { title: "亲子游" },
      //   { title: "最美建筑" },
      //   { title: "吃货必看" },
      //   { title: "吃货必看" },
      // ],
      theme: "", //推荐分类
      recommendTheme: "",
      keyWords: "", //搜索关键词
      dataList: [
        // {
        //   scenicSpotName: "上海景点",
        //   scenicSpotId: 1,
        //   img: "",
        //   description: "北京名胜古迹",
        //   close: ["近天安门广场", "人民纪念堂"],
        //   tags: [
        //     {
        //       name: "名胜古迹",
        //       bkcolour: "",
        //       colour: "0,120,255,0.50",
        //       bkrcolour: "",
        //     },
        //   ],
        //   saleTags: [
        //     {
        //       name: "可定今日",
        //       bkcolour: "",
        //       colour: "0,120,255,0.50",
        //       bkrcolour: "",
        //     },
        //   ],
        //   salePrice: "59",
        //   pricePerfixed: "￥",
        //   priceSuffix: "起",
        //   productId: "",
        //   productName: "",
        // },
      ], //内容列表
      mescrollDown: {
        callback: this.downCallback,
      },
      mescrollUp: {
        callback: this.upCallback,
        page: {
          num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 20, //每页数据条数,默认10
        },
        onScroll: this.onScroll,
        auto: false,
        htmlNodata: "",
      },
      mescroll: null,
      recommendShow: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next((vm) => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.mescroll && vm.$refs.mescroll.beforeRouteEnter();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteLeave不用写
    // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
    this.$refs.mescroll && this.$refs.mescroll.beforeRouteLeave();
    next();
  },
  mounted() {
    this.$updateTitle("门票");
  },

  methods: {
    //跳转详情
    jumptoDetail(item) {
      const query = {
        scenicSpotId: item.scenicSpotId,
      };
      const page = {
        url: "/scenic-detail",
        name: "scenic-detail",
        query: query,
        params: {},
      };
      // this.$router.push(page);
      this.$page.push(page);
    },
    onScroll(mescroll, y) {
      // if (y > 100) {
      //   this.recommendShow = true;
      // } else {
      //   this.recommendShow = false;
      // }
      // this.observer = new IntersectionObserver((entries) => {
      //   // console.log("entries", entries);
      //   entries.forEach(
      //     (entrie) => (this.recommendShow = !entrie.isIntersecting)
      //   );
      // });
    },
    handleChangeKind(item) {
      this.recommendTheme = item.code;
      this.getRecommendData(this.pageCity, this.recommendTheme).then((data) => {
        // console.log(data);
        if (data && data.product) {
          this.hotTicketList = data.product.reduce((a, b, i) => {
            a.push({
              raw: b,
              index: i,
              img: b.img || "",
              name: b.productName || "",
              price: b.salePrice || "--",
              pricePerfixed: b.pricePerfixed || "¥",
              priceSuffix: b.priceSuffix || "起",
              scenicSpotId: b.scenicSpotId || "",
              topTag: b.topTag || "",
              discount: b.discount || "",
            });
            return a;
          }, []);
        } else {
          this.hotTicketList = [];
        }
      });
    },
    //点击查看更多
    recommendShowMore() {
      // console.log(1111)
      this.mescroll.scrollTo(390, 0);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    //下拉刷新
    downCallback(mescroll) {
      // console.log("downCallback", page.num);
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getListData(page, this.keyWords, this.pageCity, this.theme)
        .then((data) => {
          console.log("downCallback-----", data);
          this.dataList = data || [];
          mescroll.endSuccess(this.dataList.length);
        })
        .catch(() => {
          mescroll.endErr();
        });
    },
    //上拉
    upCallback(page, mescroll) {
      this.getListData(page, this.keyWords, this.pageCity, this.theme)
        .then((data) => {
          if (data.length != 0) {
            if (page.num > 1) {
              console.log("upCallback", data);
              let arr = data || [];
              this.dataList = this.dataList.concat(arr);
            } else {
              console.log("upCallback2", data);
              this.dataList = data || [];
            }

            this.$nextTick(() => {
              mescroll.endSuccess(data.length);
            });
          }
        })
        .catch(() => {
          mescroll.endErr();
        });
    },
    inputSearch(content) {
      this.keyWords = content;
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 3, //每页数据条数,默认10
      };
      this.getListData(page, this.keyWords, this.pageCity).then((data) => {
        // console.log("datakeywords-----", data);
        this.dataList = data || [];
      });
    },
    changeSearch(cityData) {
      this.pageCity = cityData;
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getListData(page, this.keyWords, this.pageCity).then((data) => {
        this.dataList = data || [];
      });
      // console.log(this.pageCity);
      this.getKindData(this.pageCity).then((data) => {
        if (data) {
          this.themeList = data;
          this.recommendTheme = this.showList[0] ? this.showList[0].code : "";
          this.getRecommendData(this.pageCity, this.recommendTheme).then(
            (data) => {
              // console.log(data);
              if (data && data.product) {
                this.hotTicketList = data.product.reduce((a, b, i) => {
                  a.push({
                    raw: b,
                    index: i,
                    img: b.img || "",
                    name: b.productName || "",
                    price: b.salePrice || "--",
                    pricePerfixed: b.pricePerfixed || "¥",
                    priceSuffix: b.priceSuffix || "起",
                    scenicSpotId: b.scenicSpotId || "",
                    topTag: b.topTag || "",
                  });
                  return a;
                }, []);
              }
            }
          );
        }
      });
      // this.getHotelScenicList(1, "", this.pageCity).then((data) => {
      //   this.dataList = data.products;
      // });
      // console.log(value);
      // this.keyWords = value;
      // if (value) {
      //   this.getHotelScenicList(value, 0).then((data) => {
      //     console.log("data-----", data);
      //     this.dataList = data.products;
      //   });
      //   this.keyWords = value;
      // } else {
      //   this.getHotelScenicList("", 0).then((data) => {
      //     console.log("data-----", data);
      //     this.dataList = data.products;
      //   });
      //   this.keyWords = "";
      // }
    },
    //显示的Listtheme重新请求
    showListAxios(selectShowList) {
      this.slecetIndexList = selectShowList;
      // console.log(selectShowList);
      this.theme = "";
      this.theme = selectShowList.reduce((a, item, index) => {
        if (item != 0) {
          a += this.showList[item - 1].code;
          if (index + 1 != selectShowList.length) {
            a += ",";
          }
        }
        return a;
      }, "");
      // console.log(this.theme);

      // console.log("showListAxios-----", this.theme);
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getListData(page, this.keyWords, this.pageCity, this.theme)
        .then((data) => {
          console.log("showListAxios", data);
          this.dataList = data || [];
        })
        .catch(() => {});
    },
    //隐藏的Listtheme重新请求
    hideListAxios(selectHideList) {
      this.slecetHideIndexList = selectHideList;
      // console.log(selectHideList);
      this.theme = "";
      this.theme = selectHideList.reduce((a, item, index) => {
        a += this.hideList[item].code;
        if (index + 1 != selectHideList.length) {
          a += ",";
        }
        return a;
      }, "");
      // console.log(this.theme);
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getListData(page, this.keyWords, this.pageCity, this.theme)
        .then((data) => {
          console.log("hideListAxiosdata", data);
          this.dataList = data || [];
        })
        .catch(() => {});
      // console.log("showListAxios-----", this.theme);
    },
    //获取门票列表getCity
    getListData(page, content, pageCity, theme) {
      let obj = {
        pageNo: page.num,
        pageSize: 20,
        // depCity: LocalData.getOriginCity().cityName,
        // depCityCode: LocalData.getOriginCity().cityCode,
        arrCity: pageCity.cityName,
        arrCityCode: pageCity.cityCode,
        // arrCity: "广州",
        // arrCityCode: "440100000000",
        // longitude: this.getPosition.lng || null,
        // longitude: this.getPosition ? this.getPosition.lng : "",
        // latitudes: this.getPosition ? this.getPosition.lat : "",
        content: content,
        theme: theme,
      };
      return service.r215043(obj).then((res) => get(res, "res.bd.data", []));
    },
    //获取门票产品类型
    getKindData(pageCity) {
      let obj = {
        dtype: "3", // 1 产品类型  2 跟团游类型  3 门票主题  4 跟团游主题
        arrCity: pageCity.cityName,
        arrCityCode: pageCity.cityCode,
      };
      return service.r215037(obj).then((res) => get(res, "res.bd.data", []));
    },
    //获取门票推荐信息
    getRecommendData(pageCity, theme) {
      let obj = {
        position: "4", // 位置 1 app首页 2 旅游首页当地推荐  3 旅游首页热门推荐 4 门票首页当地推荐  5活动推荐|
        pageSize: 6,
        city: pageCity.cityName,
        cityCode: pageCity.cityCode,
        tag: theme, // 旅游首页标签分类查询有值 所选标签 第一次默认使用 标签接口返回的第一个
      };
      return service.r215036(obj).then((res) => get(res, "res.bd.data", []));
    },
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
    // //获取定位
    // getPosition() {
    //   getLocation().then((curLocation) => {
    //     // this.$set(this.location, 'location', curLocation);
    //     let opts = {};
    //     if (curLocation.lat && curLocation.lng) {
    //       opts.lat = curLocation.lat;
    //       opts.lng = curLocation.lng;
    //     }
    //     return opts;
    //   });
    // },
    // 是否显示theme
    recommendShowComputed() {
      if (this.recommendShow || this.dataList.length < 2) {
        return true;
      } else {
        return false;
      }
    },
    //推荐列表的导航栏
    showList() {
      this.$nextTick(() => {
        if (this.$refs.kindBar) {
          this.observer.observe(this.$refs.kindBar.$el);
        } else {
          this.recommendShow = true;
        }
      });
      return this.themeList.filter((item) => item.recommendFlag === 1);
    },
    //推荐列表的导航栏
    hideList() {
      return this.themeList.filter((item) => item.recommendFlag === 0);
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });

      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
    }
  },
  created() {
    this.observer = new IntersectionObserver((entries) => {
      console.log("entries", entries);
      entries.forEach(
        (entrie) => (this.recommendShow = !entrie.isIntersecting)
      );
    });
    this.getKindData(this.pageCity).then((data) => {
      if (data) {
        this.themeList = data;
        this.recommendTheme = this.showList[0] ? this.showList[0].code : "";
        this.getRecommendData(this.pageCity, this.recommendTheme).then(
          (data) => {
            // console.log(data);
            if (data && data.product) {
              this.hotTicketList = data.product.reduce((a, b, i) => {
                a.push({
                  raw: b,
                  index: i,
                  img: b.img || "",
                  name: b.productName || "",
                  price: b.salePrice || "--",
                  pricePerfixed: b.pricePerfixed || "¥",
                  priceSuffix: b.priceSuffix || "起",
                  scenicSpotId: b.scenicSpotId || "",
                  topTag: b.topTag || "",
                });
                return a;
              }, []);
            }
            // console.log("this.$refs.kindBar", this.$refs.kindBar)

            if (this.$refs.kindBar) {
              this.observer.observe(this.$refs.kindBar.$el);
            } else {
              this.recommendShow = true;
            }
          }
        );
      }
    });

    // this.hotTicketList = this.recommendData.reduce((a, b, i) => {
    //   a.push({
    //     raw: b,
    //     index: i,
    //     img: b.img || "",
    //     name: b.productName || "",
    //     price: b.salePrice || "--",
    //     pricePerfixed: b.pricePerfixed || "¥",
    //     priceSuffix: b.priceSuffix || "起",
    //   });
    //   return a;
    // }, []);
    // this.getListData();
    // this.getKindData();
    // this.getRecommendData();
    // console.log(this.getPosition());
  },
  mounted() {
    // console.log("this.$refs.mescroll", this.$refs.mescroll);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.list {
  position: relative;
  background: #f2f4f7;
}
.recommend-card {
  // height: 408 * $px;
  padding-bottom: 12 * $px;
  margin-bottom: 8 * $px;
}
.contain {
  // padding-left: 6 * $px;
  padding-top: 12 * $px;
  background: #f2f4f7;
  padding-bottom: 40 * $px;
  .recommend-list {
    padding: 0 10 * $px;
  }
  .nothing {
    width: 363 * $px;
  }
}
.mescroll {
  position: fixed;
  top: calc(44px + constant(safe-area-inset-top));
  top: calc(44px + env(safe-area-inset-top));
  bottom: 0;
  height: auto;
  background-color: #f2f4f7;
}
.mescrollAndriod {
  position: fixed;
  top: 88px;
  width: 375 * $px;
  top: 88px;
  bottom: 0;
  height: auto;
  background-color: #f2f4f7;
}
</style>
