<template>
  <div class="list-item" @click="jumptoDetail">
    <div class="img">
      <img :src="data.img" alt="" />
      <div class="discount" v-if="data.discount">{{data.discount}}折商旅特惠</div>
    </div>
    <div class="contain">
      <div class="scenicSpotName">{{ data.scenicSpotName }}</div>
      <div class="description" v-if="data.description">{{ data.description.trim() }}</div>
      <div class="close">
        <div class="closeitem" v-for="(item, index) in data.close" :key="index + 'close'">
          {{ item }}<span v-if="index + 1 !== data.close.length">/</span>
        </div>
      </div>
      <div class="tags">
        <div class="tagsItem" v-for="(item, index) in data.tags" :key="index + 'tags'" :style="{ color: 'rgba(' + item.colour + ')' }">
          {{ item.name }}
        </div>
      </div>
      <div class="saleTags">
        <div class="saleTagsList">
          <div class="saleTagsItem" v-for="(item, index) in data.saleTags" :key="index + 'saleTags'" :style="{ color: 'rgba(' + item.colour + ')' }">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="salePrice">
        <span class="pricePerfixed">{{ data.pricePerfixed }}</span>{{ data.salePrice
        }}<span class="priceSuffix">{{ data.priceSuffix }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "list-item",
  props: {
    data: { type: Object, default: () => ({}) },
  },
  data() {
    return {};
  },
  methods: {
    jumptoDetail() {
      this.$emit("jumptoDetail", this.data);
    },
  },
  created() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.list-item {
  position: relative;
  width: 363 * $px;
  height: 137 * $px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 6 * $px;
  display: flex;
  justify-content: space-between;
  padding: 8 * $px 10 * $px 9 * $px 10 * $px;
  margin-bottom: 4 * $px;
  .img {
    width: 100 * $px;
    height: 120 * $px;
    position: relative;
    .discount {
      width: 84 * $px;
      height: 20 * $px;
      background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
      border-radius: 0px 8 * $px 0px 8 * $px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      bottom: 0;
      font-size: 11*$px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 400;
      color: #ffffff;
    }
    img {
      width: 100 * $px;
      height: 120 * $px;
      object-fit: cover;
    }
  }
  .contain {
    width: 231 * $px;
    .scenicSpotName {
      font-size: 15 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #262626;
      margin-bottom: 4 * $px;
      //   line-height: 21px;
    }
    .description {
      width: 231 * $px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #262626;
      margin-bottom: 4 * $px;
    }
    .close {
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(52, 56, 75, 0.75);
      margin-bottom: 8 * $px;
      display: flex;
      justify-content: flex-start;
      //   line-height: 17px;
      //   .closeitem {
      //   }
    }
    .tags {
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      .tagsItem {
        padding: 0 3 * $px;
        height: 16 * $px;
        border: 1 * $px solid rgba(0, 120, 255, 0.5);
        border-radius: 4 * $px;
        font-size: 11 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0078ff;
        display: flex;
        align-items: center;
        margin-right: 4 * $px;
        // box-sizing: border-box;
        // line-height: 16 * $px;
      }
    }
    .saleTags {
      position: absolute;
      top: 112 * $px;
      left: 122 * $px;
      height: 16 * $px;
      font-size: 11 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ff6814;
      .saleTagsList {
        display: flex;
        justify-content: flex-start;
        .saleTagsItem {
          margin-right: 8 * $px;
        }
      }
    }
    .salePrice {
      position: absolute;
      top: 107 * $px;
      right: 10 * $px;
      font-size: 18 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ff6814;
      //   line-height: 17px;
      .pricePerfixed {
        font-size: 12 * $px;
      }
      .priceSuffix {
        font-size: 12 * $px;
      }
    }
  }
}
</style>
